import React, { useEffect, useState } from "react";
import { DatePicker, Statistic, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { format, addDays } from "date-fns";
import "./style.scss";

import { Card } from "../../../components";
import { transformStatusData, sortByDate, formatDate } from "../../../utils/admin";
import { Doughnut, Bar, defaults } from "react-chartjs-2";
import moment from "moment";
import { get, post } from "../../../utils/axios";
import { useAppState } from "../../../context";
import { getHostTag } from "../../../utils/host";
import { Scatter } from "@ant-design/charts";
import { Treemap, Pie, Column } from "@ant-design/plots";
import { APP_DATE_FORMAT } from "@assets/const/form";
import { getRenewalPolicyStatus } from "@utils/common";
import {
  COLOR_COMPLIANT,
  COLOR_NON_COMPLIANT,
  COLOR_UN_VERIFIED,
  COLOR_PENDING,
} from "@assets/const/theme";

const { RangePicker } = DatePicker;

export default function AdminOverview() {
  const [loading, setLoading] = useState(false);
  const [loadingScore, setLoadScore] = useState(false);
  const [adminComplianceData, setAdminComplianceData] = useState([]);
  const [adminPolicyData, setAdminPolicyData] = useState([]);
  const [expiringCertData, setExpiringCertData] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const { franchisorAdmin } = useAppState();
  const [reportScore, setReportScore] = useState([]);
  const [reportBrand, setReportBrand] = useState({
    countFranchisor: 0,
    countFranchisee: 0,
    brandList: []
  });
  const [report, setReport] = useState({
    countFranchisor: 0,
    countFranchisee: 0,
    countCoi: 0,
    countExpired: 0,
    countCompliant: 0,
    countNonCompliant: 0,
    countPending: 0,
    countUnderDevelopment: 0
  });
  const [dateRange, setDateRange] = useState({
    startDay: "2020-01-01",
    endDay: format(addDays(new Date(), 1), 'yyyy-MM-dd')
  });

  useEffect(() => {
    handleFetchReport();
    handleFetchReportScore();
    handleFetchReportBrand();
    handleFetchExpiringCertData();
    handleFetchAdminReports();
  }, [dateRange, franchisorAdmin]);

  async function handleFetchExpiringCertData() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();

      const result = await post(
        `admin/expired-certs-data`,
        {
          host: getHostTag(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (result.status === 200) {
        const convertedData = result?.data?.data.map((item) => {
          return {
            ...item,
            date: formatDate(item.date),
          };
        });

        setExpiringCertData(convertedData);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  async function handleFetchAdminReports() {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const host = getHostTag();

      const result = await get(`admin/admin-report`, {
        host,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.status === 200) {
        const transformedData = transformStatusData(result?.data);
        // Update adminComplianceData
        setAdminComplianceData((prevData) => {
          const newComplianceData = [];
          transformedData.forEach(({ complianceData }) => {
            complianceData.forEach((item) => {
              // Check if an entry with the same createdAt already exists
              const exists = prevData.some((existingItem) => existingItem.date === item.date);
              if (!exists) {
                newComplianceData.push(item);
              }
            });
          });
          return sortByDate([...prevData, ...newComplianceData]);
        });

        // Update adminPolicyData
        setAdminPolicyData((prevData) => {
          const newPolicyData = [];
          transformedData.forEach(({ policyData }) => {
            policyData.forEach((item) => {
              // Check if an entry with the same createdAt already exists
              const exists = prevData.some((existingItem) => existingItem.date === item.date);
              if (!exists) {
                newPolicyData.push(item);
              }
            });
          });
          return sortByDate([...prevData, ...newPolicyData]);
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  // TODO: delete this after testing
  // async function createAdminReport() {
  //   setLoading(true);

  //   try {
  //     const token = await getAccessTokenSilently();
  //     const host = getHostTag();
  //     const date = new Date();
  //     // date for next month
  //     date.setMonth(date.getMonth() + 1);

  //     const result = await post(
  //       `admin/create-admin-report`,
  //       {
  //         host,
  //         date
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //         }
  //       }
  //     );

  //     if (result.status === 201) {
  //       console.log('Admin report created successfully');
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error(error);
  //   }
  // }

  async function handleFetchReportScore() {
    setLoadScore(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/report-score`,
        {
          startDay: dateRange.startDay,
          endDay: dateRange.endDay,
          franchisorId: franchisorAdmin?._id,
          host: getHostTag()
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (result?.data?.data) {
        setReportScore(result?.data?.data);
      }
      setLoadScore(false);
    } catch (error) {
      setLoadScore(false);
    }
  }

  async function handleFetchReportBrand() {
    setLoadScore(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/report-brand`,
        {
          franchisorId: franchisorAdmin?._id,
          host: getHostTag()
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result?.data?.requirements) {
        setReportBrand({
          countFranchisor: result?.data?.countFranchisor || 0,
          countFranchisee: result?.data?.countFranchisee || 0,
          countCustomer: result?.data?.countCustomer || 0,
          brandList: result?.data?.requirements.map((item) => {
            return {
              name: item.personal_infor_business_name,
              value: item.franchisees
            };
          })
        });
      }
      setLoadScore(false);
    } catch (error) {
      setLoadScore(false);
    }
  }

  async function handleFetchReport() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/report`,
        {
          startDay: dateRange.startDay,
          endDay: dateRange.endDay,
          franchisorId: franchisorAdmin?._id,
          host: getHostTag()
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.status === 200) {
        const data = result.data;
        setReport({
          countFranchisor: data.franchisor[0]?.total[0]?.count || 0,
          countFranchisee: data.franchisee[0]?.total[0]?.count || 0,
          countCoi: data.franchisee[0]?.coi[0]?.count || 0,
          countExpired: data.expired[0]?.count || 0,
          countCompliant: data.franchisee[0]?.compliant[0]?.count || 0,
          countNonCompliant: data.franchisee[0]?.nonCompliant[0]?.count || 0,
          countPending: data.franchisee[0]?.pending[0]?.count || 0,
          countUnderDevelopment:
            data.franchisee[0]?.underDevelopment[0]?.count || 0
        });
      }
    } catch (err) {}
  }

  async function handleAlterPolicyData(result) {
    const { requirements } = result?.data;

    // Step 1: Process requirements to extract createdAt and result
    const data = requirements?.flatMap((zor) => {
      return zor?.franchiseesList?.map((zee) => {
        const policyStatus = getRenewalPolicyStatus(zee, zor);
        const monthYear = format(new Date(zee.createdAt), "MM-yyyy");

        return {
          createdAt: monthYear,
          result: policyStatus
        };
      });
    });

    // Step 2: Use the helper function to aggregate the counts
    const aggregatedData = aggregatePolicyData(data);

    return aggregatedData;
  }

  function aggregatePolicyData(data) {
    return Object.values(
      data.reduce((acc, curr) => {
        const key = `${curr.createdAt}-${curr.result}`;
        if (!acc[key]) {
          acc[key] = {
            createdAt: curr.createdAt,
            result: curr.result,
            count: 0,
          };
        }
        acc[key].count += 1;
        return acc;
      }, {})
    );
  }

  function handleDateFilter(date) {
    setDateRange({
      startDay: date[0].format("YYYY-MM-DD"),
      endDay: date[1].format("YYYY-MM-DD")
    });
  }

  function renderFilter() {
    return (
      <RangePicker
        format={APP_DATE_FORMAT}
        onChange={handleDateFilter}
        defaultValue={[moment(dateRange.startDay), moment(dateRange.endDay)]}
      />
    );
  }

  const requestData = [
    {
      type: "COI Document Uploaded",
      value: report.countCoi,
      backgroundColor: "#888"
    },
    {
      type: "Pending Document Upload",
      value: reportBrand?.countFranchisee - report.countCoi,
      backgroundColor: "#503678"
    }
  ];

  const analysisConfig = {
    appendPadding: 30,
    data: reportScore,
    xField: "count",
    yField: "score",
    colorField: "franchisorName",
    size: 5,
    shape: "circle",
    pointStyle: {
      fillOpacity: 1
    },
    yAxis: {
      nice: true,
      line: {
        style: {
          stroke: "#aaa"
        }
      }
    },
    xAxis: {
      grid: {
        line: {
          style: {
            stroke: "#eee"
          }
        }
      },
      line: {
        style: {
          stroke: "#aaa"
        }
      }
    },
    label: {}
  };

  const growthData = {
    name: "Undefined",
    children: reportBrand.brandList
  };
  const growthConfig = {
    data: growthData,
    colorField: "name"
  };

  const requesConfig = {
    appendPadding: 10,
    data: requestData,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 10
      }
    },
    interactions: [
      {
        type: "element-selected"
      },
      {
        type: "element-active"
      }
    ],
    legend: {
      position: "bottom"
    },
    color: ["#c93e86", "#4c3a71"],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "overflow",
          textOverflow: "ellipsis"
        },
        content: ""
      }
    }
  };

  const complianceConfig = {
    data: adminComplianceData,
    isGroup: false,
    xField: 'date',
    yField: 'count',
    seriesField: 'status',
    columnStyle: {
      radius: [0, 0, 0, 0],
    },
    color: (({ status }) => {
      if (status === 'Compliant') {
        return COLOR_COMPLIANT;
      }
      if (status === 'Non-Compliant') {
        return COLOR_NON_COMPLIANT;
      }
      if (status === 'Pending') {
        return COLOR_PENDING;
      }
    }),
    label: {
      position: 'middle',
      style: {
        fill: '#000000',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      title: {
        text: 'Count',
      },
    },
    tooltip: {
      shared: true,
      showMarkers: false,
    },
  };

  const policyConfig = {
    data: adminPolicyData,
    xField: 'date', // The field to be used for the X-axis (the date in MM-YYYY format)
    yField: 'count',     // The field to be used for the Y-axis (the count of each createdAt)
    seriesField: 'status', // This field will group data by status value and show different colors
    isStack: true, // Stack different results on the same column to distinguish categories
    label: {
      position: 'middle',
      style: {
        fill: '#000000',
        opacity: 0.6,
      },
    },
    columnStyle: {
      radius: [0, 0, 0, 0], // Add rounded corners to the top of the columns
    },
    color: (({ status }) => {
      if (status === 'Compliant') {
        return COLOR_COMPLIANT;
      }
      if (status === 'Not-Verified') {
        return COLOR_UN_VERIFIED;
      }
      if (status === 'Expiring') {
        return COLOR_PENDING;
      }
      if (status === 'Lapsed') {
        return COLOR_NON_COMPLIANT;
      }
    }),
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      title: {
        text: 'Count', // Title for the Y-axis
      },
    },
    tooltip: {
      shared: true,
      showMarkers: false,
    },
    legend: {
      position: 'top-left', // Position the legend at the top left
    },
  };

  const ExpiringCertsConfig = {
    data: expiringCertData,
    xField: 'date',
    yField: 'count',
    seriesField: 'status',
    label: {
      position: 'middle',
      style: {
        fill: '#000000',
        opacity: 0.6,
      },
    },
    color: (({ status }) => {
      if (status === 'Expiring') {
        return COLOR_NON_COMPLIANT;
      }
    }),
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      title: {
        text: 'Count',
      },
    },
    tooltip: {
      shared: true,
      showMarkers: false,
    },
  };

  return (
    <div className="admin_overview">
    {/* test buttons */}
      {/* <Button type="primary" onClick={createAdminReport}>
          Create Admin Report
      </Button>
      <Button type="primary" onClick={handleFetchAdminReports}>
          get Admin Report
      </Button> */}
      <Card title="Franchise Rolling Data">
        <div className="Franchise bar-graphs">
          <div className="chart-container">
            <p className="chart-text">Policy Status</p>
            <Column {...policyConfig} height={300} />
          </div>
          <div className="chart-container">
            <p className="chart-text">Compliance Status</p>
            <Column {...complianceConfig} height={300} />
          </div>
          <div className="chart-container">
            <p className="chart-text">Expiration Dates</p>
            <Column {...ExpiringCertsConfig} height={300} />
          </div>
          <Button type="primary">View Franchisees &#187;</Button>
        </div>
      </Card>
      <Card title="Franchise Analysis">
        <Scatter {...analysisConfig} height={800} />
      </Card>
      <Card title="Franchisee Growth">
        <div className="admin_overview_report">
          <Statistic
            title="Total Franchisors"
            value={reportBrand?.countFranchisor}
          />
          <Statistic
            title="Total Franchisees"
            value={reportBrand?.countFranchisee}
          />
          <Statistic
            title="Total Rikor Customers"
            value={reportBrand?.countCustomer}
          />
        </div>
        <Treemap {...growthConfig} height={800} />
      </Card>
      <Card title="COI Verification" extra={renderFilter}>
        <div className="admin_overview_wrapper">
          <Pie {...requesConfig} />
        </div>
      </Card>
    </div>
  );
}
