import React, { useState, useEffect, useMemo } from "react";
import { Breadcrumb, Tabs, Select, DatePicker } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Input, Loading, OriginalRecordForm } from "@components";
import "./style.scss";
import { get, post, put } from "@utils/axios";
import InsuranceUpload from "./insurance-upload";
import UnderWriting from "./under-writing";
import PortalLink from "./portal-link";
import DocumentList from "./document-list";
import ActivityList from "./activity-list";
import ComplianceList from "./compliance-list";
import InsuredInfor from "./insured-infor";
import ConsultantInfor from "./consultant-infor";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import QuoteProposal from "./quote-proposal";
import PolicyDetails from "./policy-details";
import POCContact from "./poc-contact";
import Integration from "./integration";
import {
  DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
  DO_NOT_CONTACT_OPTION_INACTIVE_ZEE,
  DO_NOT_CONTACT_OPTION_IS_LEAD,
  DO_NOT_CONTACT_OPTION_NONE,
  DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
  DO_NOT_CONTACT_OPTION_NOT_OPEN,
  DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
  DO_NOT_CONTACT_OPTION_CANCEL_POLICY
} from "@assets/const/status";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { ButtonGroup } from "@components";
import { APP_DATE_FORMAT } from "@assets/const/form";
import { VIEW_SOURCE_ADMIN } from "@assets/const/ui";
import ComplianceSection from "./compliance-section";
import { BUTTON_GROUP_TYPE_BUTTON } from "@components/button-group";

const { TabPane } = Tabs;

const TAB_ITEM_ACCOUNT = "TAB_ITEM_ACCOUNT";
const TAB_ITEM_INTEGRATION = "TAB_ITEM_INTEGRATION";
const TAB_ITEM_UNDERWRITING = "TAB_ITEM_UNDERWRITING";
const TAB_ITEM_COMPLIANCE = "TAB_ITEM_COMPLIANCE";
const TAB_ITEM_QUOTE_PROPOSAL = "TAB_ITEM_QUOTE_PROPOSAL";
const TAB_ITEM_POLICY = "TAB_ITEM_POLICY";
const TAB_DOCUMENTATION = "TAB_DOCUMENTATION";

const DO_NOT_CONTACT_OPTION = [
  {
    value: DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
    label: "Rikor Customer"
  },
  {
    value: DO_NOT_CONTACT_OPTION_INACTIVE_ZEE,
    label: "Inactive Zee"
  },
  {
    value: DO_NOT_CONTACT_OPTION_IS_LEAD,
    label: "New Lead"
  },
  {
    value: DO_NOT_CONTACT_OPTION_NOT_OPEN,
    label: "Not Yet Open for Business"
  },
  {
    value: DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
    label: "Rikor Cannot Obtain Coverage"
  },
  {
    value: DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
    label: "Canadian Business"
  },
  {
    value: DO_NOT_CONTACT_OPTION_CANCEL_POLICY,
    label: "Cancel Policy"
  },
  {
    value: DO_NOT_CONTACT_OPTION_NONE,
    label: "Non-Rikor Customer"
  }
];

export default function FranchiseeDetail(props) {
  const history = useHistory();
  let { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const [franchiseeData, setFranchiseeData] = useState({});
  const [franchisorData, setFranchisorData] = useState({});
  const [consultantData, setConsultantData] = useState(null);
  const [dataSourceActivity, setDataSourceActivity] = useState([]);
  const [visibleOri, setVisibleOri] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      handleLoadFranchisee();
    }
  }, []);

  async function handleLoadFranchisee() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFranchiseeData(result.data.data || {});
      setDataSourceActivity((result.data.data.activities || []).reverse());
      const resultRequirement = await get(
        `requirement/${result.data.data.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchisorData(resultRequirement.data.data);
      if (resultRequirement?.data?.data?.consultantId) {
        const resultConsultant = await get(
          `user/${resultRequirement?.data?.data?.consultantId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setConsultantData(resultConsultant?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  }

  function handleNavigateBack() {
    history.push("/admin/franchisees");
  }

  function handleViewSummary() {
    history.push(`/admin/franchisees/${id}/summary`);
  }

  function handleViewReport() {
    history.push(`/admin/franchisees/${id}/report`);
  }

  async function handleSave(key, value) {
    setLoading(true);
    try {
      const param = {
        [key]: value
      };

      const token = await getAccessTokenSilently();
      const result = await put(`contact/${franchiseeData._id}`, param, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFranchiseeData(result.data.entity);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function handleSyncAC() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/active-campaign/sync-franchisee/${franchiseeData._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function handleMarketplaceFlow() {
    history.push(`/admin/franchisees/${id}/insurance-quote`);
  }

  const operations = (
    <div className="toolbar_wrapper">
      <div className="toolbar_wrapper-ele">
        <div className="toolbar_wrapper-ele-label">Record Status:</div>
        <Select
          style={{ minWidth: "150px" }}
          placeholder="Record Status"
          onChange={(val) => handleSave("doNotContact", val)}
          options={DO_NOT_CONTACT_OPTION}
          value={franchiseeData?.doNotContact}
          size="large"
        />
      </div>
      <div className="toolbar_wrapper-ele">
        <div className="toolbar_wrapper-ele-label">Timer:</div>
        <DatePicker
          format={APP_DATE_FORMAT}
          value={
            !isEmpty(franchiseeData?.timePeriod)
              ? dayjs(franchiseeData?.timePeriod)
              : null
          }
          onChange={(val) => {
            if (val) {
              handleSave("timePeriod", val.toISOString());
            } else {
              handleSave("timePeriod", null);
            }
          }}
          size="large"
        />
      </div>
    </div>
  );

  const franchiseeName = useMemo(() => {
    if (franchiseeData) {
      
      if (franchiseeData?.isInsureDiff) {
        const { first_name, last_name } = franchiseeData?.insuranceData || {};
        return first_name && last_name
          ? `${first_name} ${last_name}`
          : franchiseeData?.entity_name;
      }

      const { first_name, last_name, entity_name } = franchiseeData;
      return first_name && last_name
        ? `${first_name} ${last_name}`
        : entity_name;
    }

    return "Franchisee Name";
  }, [franchiseeData]);


  return (
    <div className="zee_detail">
      {franchisorData?.personal_infor_logo && (
        <div className="franchisor-logo-container">
          <img
            className="franchisor-logo-container-img"
            src={franchisorData?.personal_infor_logo}
          />
        </div>
      )}
      <div className="header-wrapper">
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleNavigateBack}>
            {franchisorData?.personal_infor_business_name || "Franchisor"}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{franchiseeName}</Breadcrumb.Item>
        </Breadcrumb>

        <div className="header-wrapper-action">
          <ButtonGroup
            type={BUTTON_GROUP_TYPE_BUTTON}
            buttonList={[
              {
                label: "Sync",
                onClick: handleSyncAC
              },
              {
                label: "Quote",
                onClick: handleMarketplaceFlow
              },
              {
                label: "COI",
                onClick: handleViewSummary
              },
              {
                label: "Report",
                onClick: handleViewReport
              }
            ]}
          />
        </div>
      </div>

      <div className="zee_detail-container">
        <Tabs
          type="card"
          defaultActiveKey={TAB_ITEM_ACCOUNT}
          tabBarExtraContent={operations}
        >
          <TabPane tab="Account" key={TAB_ITEM_ACCOUNT}>
            <div className="zee_detail-container-acc">
              <div>
                <InsuredInfor franchiseeData={franchiseeData} />
                {/* we only need to see insured information. currenlty this is confusing. Asked to becommented out by Jonathan */}
                {/* <Card
                  title="Original Record"
                  extra={() => (
                    <div
                      className="zee_detail-container-acc-action"
                      onClick={() => setVisibleOri(!visibleOri)}
                      type="primary"
                    >
                      {!visibleOri ? (
                        <CaretDownOutlined />
                      ) : (
                        <CaretUpOutlined />
                      )}
                    </div>
                  )}
                >
                  {visibleOri && (
                    <>
                      <OriginalRecordForm
                        disabled={true}
                        data={franchiseeData}
                      />

                      <div className="form-group-container">
                        <div className="line-item-container">
                          <Input
                            title="RIKOR ID"
                            type="text"
                            value={franchiseeData._id}
                            disabled
                          />
                        </div>
                      </div>
                    </>
                  )}
                </Card> */}

                <ConsultantInfor consultantData={consultantData} />
                <PolicyDetails franchiseeData={franchiseeData} />
              </div>
              <div>
                <POCContact
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  setFranchiseeData={setFranchiseeData}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Schedule" key={TAB_ITEM_UNDERWRITING}>
            <UnderWriting
              franchiseeData={franchiseeData}
              franchisorData={franchisorData}
              setFranchiseeData={setFranchiseeData}
              viewSource={VIEW_SOURCE_ADMIN}
            />
          </TabPane>
          <TabPane tab="Compliance" key={TAB_ITEM_COMPLIANCE}>
            <div>
              <ComplianceSection franchiseeData={franchiseeData} />
              <ComplianceList
                franchiseeData={franchiseeData}
                franchisorData={franchisorData}
              />
              <DocumentList
                franchiseeData={franchiseeData}
                updateFranchiseeData={setFranchiseeData}
              />
              <ActivityList franchiseeData={franchiseeData} />
              <PortalLink franchiseeData={franchiseeData} />
              <InsuranceUpload />
            </div>
          </TabPane>
          <TabPane tab="Proposal" key={TAB_ITEM_QUOTE_PROPOSAL}>
            <div>
              <QuoteProposal franchiseeData={franchiseeData} />
            </div>
          </TabPane>
          <TabPane tab="Policy" key={TAB_ITEM_POLICY}></TabPane>
          <TabPane tab="Integration" key={TAB_ITEM_INTEGRATION}>
            <Integration data={franchiseeData} />
          </TabPane>
        </Tabs>

        <Loading loading={loading} />
      </div>
    </div>
  );
}
